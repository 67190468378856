
// @ is an alias to /src
import { defineComponent, ref, onMounted } from 'vue';
import { TransitionRoot, TransitionChild, Dialog } from '@headlessui/vue';
import useBackend from '@/services/api.service';

// const dataResult = [
//   {
//     "displayName": "1mg Booking Module",
//     "logo": "https://www.1mg.com/images/tata_1mg_logo.svg",
//     "bookingUrl": "https://tdgx9.app.goo.gl/?link=https%3A%2F%2Fearth.1mg.com%2Fmy-account%2Fuhi-search-results%2Fanusheel%40phpr1%3FdoctorId%3Danusheel%40phpr1&apn=com.aranoah.healthkart.plus&amv=203&al=onemg-web://earth.1mg.com%2Fmy-account%2Fuhi-search-results%2Fanusheel%40phpr1%3FdoctorId%3Danusheel%40phpr1&ibi=com.aranoah.healthkart"
//   },
//   {
//     "displayName": "Meddo Booking Module",
//     "logo": "https://res.cloudinary.com/ankitmeddo/image/upload/f_auto,c_limit,w_3840,q_100/assets/images/common/meddo-logo-white-new.svg",
//     "bookingUrl": "https://uhi-ui.meddo.in/anusheel@phpr1"
//   }
// ]

export default defineComponent ({
  name: 'HomeView',
  components: {
    TransitionRoot,
    TransitionChild,
    
    Dialog
  },
  props:[
    "phpr"
  ],

  setup(props) {
    const uhiModal = ref(true);
    const results = ref([]);

    function loadUhiServiceType() {
      useBackend().getBookingModules(props.phpr).then(resp => {
        
        results.value = resp.data;
      })
    }

    onMounted(() => {
      loadUhiServiceType();
    });
   
    return {
      results,
      uhiModal
    }
  }
});
