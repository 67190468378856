import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   redirect: "/bm"
  // },
  {
    path: "/bm",
    name: "home",
    component: HomeView,
    props: route => ({ phpr: route.query.phpr })
  },
  {
    path: "/hpOnboarding",
    name: "user.home",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/hpOnboarding/HomeView.vue"),
    props: true,
  },
  {
    path: "/hpOnboarding/thank-you",
    name: "user.thank-you",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/hpOnboarding/ThankYouView.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
