import axios, { AxiosInstance } from "axios";


const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
    "Signature": "finarkein-test"
  },
  timeout: 10000,
  withCredentials: false,
});

export default function useBackend() {
  const getBookingModules = async (id: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/p/bm?phpr=${id}`);
  };

  const loginViaOtp = async (mobile: string) => {
    return await http.post(`${process.env.VUE_APP_API_BASE}/p/auth/loginViaMobileSendOTP`, {
      "mobile": mobile
    });
  };

  const loginVerifyOtp = async (inputs: {}) => {
    return await http.post(`${process.env.VUE_APP_API_BASE}/p/auth/loginViaMobileVerifyOTP`, inputs);
  };

  const checkHpPresent = async (phoneNum: string) => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/p/checkIfHpIsPresent?phoneNumber=${phoneNum}`);
  };

  const getHpDiaries = async () => {
    return await http.get(`${process.env.VUE_APP_API_BASE}/p/hpDiaries`);
  };

  const onboardHp = async (inputs: {}) => {
    return await http.post(`${process.env.VUE_APP_API_BASE}/p/onboardHp`, inputs);
  };

  return {
    getBookingModules,
    loginViaOtp,
    loginVerifyOtp,
    checkHpPresent,
    getHpDiaries,
    onboardHp
  };
}
